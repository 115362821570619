<h1 mat-dialog-title>Dataset Details</h1>
<div mat-dialog-content>
	Row numbers are counted from 1, starting at the first actual content row. Header rows are not considered here.
	<table mat-table [dataSource]="dataSource" aria-label="Dataset Details">
		<ng-container matColumnDef="row">
			<th mat-header-cell *matHeaderCellDef>Row</th>
			<td mat-cell *matCellDef="let element">{{element.row}}</td>
		</ng-container>

		<ng-container matColumnDef="message">
			<th mat-header-cell *matHeaderCellDef>Message</th>
			<td mat-cell *matCellDef="let element">{{element.message}}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
<div mat-dialog-actions>
	<button mat-button color="primary" (click)="onClickClose()" id="closeButton">Close</button>
</div>

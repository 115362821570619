<mat-expansion-panel *ngIf="sumCompleted > 0 || sumUploading > 0 || sumFailed > 0">
	<mat-expansion-panel-header>
		<mat-panel-title>
			Uploads
		</mat-panel-title>
		<mat-panel-description>
			<span style="margin-right: 10px;" *ngIf="sumUploading > 0">{{sumUploading}} in progress</span>
			<span style="margin-right: 10px;" *ngIf="sumCompleted > 0">{{sumCompleted}} completed</span>
			<span style="margin-right: 10px;" *ngIf="sumFailed > 0">{{sumFailed}} failed or cancelled</span>
		</mat-panel-description>
	</mat-expansion-panel-header>
	<mat-list>
		<mat-list-item *ngFor="let file of files">
			<fa-icon [icon]="fileExcel" size="2x" style="float: left; margin-right: 5px;" />
			<div>
				{{file.upload.fileName}}
				<div *ngIf="file.upload.progress && !file.upload.completed">
					<mat-progress-bar class="progress-bar" style="display: inline-block;" mode="determinate"
						[value]="file.upload.progress" />
				</div>
				<div style="color:green; font-size: 16px;" *ngIf="file.upload.completed && !file.upload.error">
					<mat-icon [inline]="true" style="vertical-align: text-bottom;">check_circle</mat-icon>
					Completed
				</div>
				<div style="color: red; font-size: 16px;" *ngIf="file.upload.error">
					<mat-icon [inline]="true" style="vertical-align: text-bottom;">error</mat-icon>
					{{file.upload.error}}
				</div>
			</div>
		</mat-list-item>
	</mat-list>
</mat-expansion-panel>

<input type="file" class="file-input" (change)="onFileSelected($event)"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx" #fileInput>

<div class="file-upload">

    <button mat-icon-button color="primary" (click)="fileInput.click()" aria-label="Upload new content for data set"
        title="Upload content">
        <mat-icon>upload</mat-icon>
    </button>
</div>

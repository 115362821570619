<h1 mat-dialog-title>Create Dataset</h1>
<div mat-dialog-content>
	<mat-form-field>
		<mat-label>Dataset Name</mat-label>
		<input matInput required [(ngModel)]="datasetName">
	</mat-form-field>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="onClickCancel()" id="cancelButton">Cancel</button>
	<button mat-button color="primary" (click)="onClickCreate()" id="createButton">Create Dataset</button>
</div>


// Operations matching the operations defined in server-side roles.
// Compared client-side with output of /me/operations
// to show/hide buttons and menus as needed.
export enum Operations {
    GetDatasets = "GetDatasets",
    GetDatasetVersionData = "GetDatasetVersionData",
    ReplaceDataset = "ReplaceDataset",
    CreateDataset = "CreateDataset",
    GetViewData = "GetViewData",
}

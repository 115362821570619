/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Dataset } from '../models/dataset';
import { DatasetData } from '../models/dataset-data';
import { DatasetId } from '../models/dataset-id';
import { DatasetList } from '../models/dataset-list';
import { DatasetVersion } from '../models/dataset-version';
import { DatasetVersionContent } from '../models/dataset-version-content';
import { DatasetVersionId } from '../models/dataset-version-id';
import { DatasetVersionList } from '../models/dataset-version-list';
import { DatasetVersionStatus } from '../models/dataset-version-status';
import { EventList } from '../models/event-list';
import { EventType } from '../models/event-type';
import { MaterialList } from '../models/material-list';
import { OperationList } from '../models/operation-list';
import { Pat } from '../models/pat';
import { PatCreationParameters } from '../models/pat-creation-parameters';
import { PatList } from '../models/pat-list';
import { PlanningUnit } from '../models/planning-unit';
import { PlanningUnitList } from '../models/planning-unit-list';
import { RoleList } from '../models/role-list';
import { Status } from '../models/status';
import { StatusList } from '../models/status-list';
import { TokenId } from '../models/token-id';
import { User } from '../models/user';
import { UserId } from '../models/user-id';
import { UserList } from '../models/user-list';
import { Version } from '../models/version';
import { View } from '../models/view';
import { ViewContent } from '../models/view-content';
import { ViewId } from '../models/view-id';
import { ViewList } from '../models/view-list';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVersion()` */
  static readonly GetVersionPath = '/version';

  /**
   * Get the version of the currently running backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Version>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetVersionPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Version>;
      })
    );
  }

  /**
   * Get the version of the currently running backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion(
    params?: {
    },
    context?: HttpContext
  ): Observable<Version> {
    return this.getVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Version>): Version => r.body)
    );
  }

  /** Path part for operation `getViews()` */
  static readonly GetViewsPath = '/views';

  /**
   * Get a list of views visible to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getViews()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViews$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ViewList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetViewsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ViewList>;
      })
    );
  }

  /**
   * Get a list of views visible to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getViews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViews(
    params?: {
    },
    context?: HttpContext
  ): Observable<ViewList> {
    return this.getViews$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewList>): ViewList => r.body)
    );
  }

  /** Path part for operation `getView()` */
  static readonly GetViewPath = '/view/{viewId}';

  /**
   * Get metadata for a given view.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getView()` instead.
   *
   * This method doesn't expect any request body.
   */
  getView$Response(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<View>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetViewPath, 'get');
    if (params) {
      rb.path('viewId', params.viewId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<View>;
      })
    );
  }

  /**
   * Get metadata for a given view.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getView(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;
    },
    context?: HttpContext
  ): Observable<View> {
    return this.getView$Response(params, context).pipe(
      map((r: StrictHttpResponse<View>): View => r.body)
    );
  }

  /** Path part for operation `getViewData()` */
  static readonly GetViewDataPath = '/view/{viewId}/data';

  /**
   * Get the content of a view, paged json or Excel export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getViewData$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViewData$Json$Response(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ViewContent>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetViewDataPath, 'get');
    if (params) {
      rb.path('viewId', params.viewId, {});
      rb.query('offset', params.offset, {});
      rb.query('count', params.count, {});
      rb.query('download', params.download, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ViewContent>;
      })
    );
  }

  /**
   * Get the content of a view, paged json or Excel export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getViewData$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViewData$Json(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<ViewContent> {
    return this.getViewData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewContent>): ViewContent => r.body)
    );
  }

  /**
   * Get the content of a view, paged json or Excel export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetViewDataPath, 'get');
    if (params) {
      rb.path('viewId', params.viewId, {});
      rb.query('offset', params.offset, {});
      rb.query('count', params.count, {});
      rb.query('download', params.download, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get the content of a view, paged json or Excel export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(
    params: {

    /**
     * View ID
     */
      viewId: ViewId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getDatasets()` */
  static readonly GetDatasetsPath = '/datasets';

  /**
   * Get a list of datasets visible to the current user.
   *
   * Get a list of datasets visible to the current user. Only the latest dataset versions are listed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasets$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DatasetList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatasetList>;
      })
    );
  }

  /**
   * Get a list of datasets visible to the current user.
   *
   * Get a list of datasets visible to the current user. Only the latest dataset versions are listed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasets(
    params?: {
    },
    context?: HttpContext
  ): Observable<DatasetList> {
    return this.getDatasets$Response(params, context).pipe(
      map((r: StrictHttpResponse<DatasetList>): DatasetList => r.body)
    );
  }

  /** Path part for operation `createDataset()` */
  static readonly CreateDatasetPath = '/datasets';

  /**
   * Create a new dataset.
   *
   * Create a new empty dataset. This will keep all other datasets unchanged. PUT into an existing dataset to replace an existing dataset with a new version.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDataset()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDataset$Response(
    params: {

    /**
     * Dataset Name
     */
      datasetName: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Dataset>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.CreateDatasetPath, 'post');
    if (params) {
      rb.query('datasetName', params.datasetName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dataset>;
      })
    );
  }

  /**
   * Create a new dataset.
   *
   * Create a new empty dataset. This will keep all other datasets unchanged. PUT into an existing dataset to replace an existing dataset with a new version.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDataset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDataset(
    params: {

    /**
     * Dataset Name
     */
      datasetName: string;
    },
    context?: HttpContext
  ): Observable<Dataset> {
    return this.createDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dataset>): Dataset => r.body)
    );
  }

  /** Path part for operation `getDataset()` */
  static readonly GetDatasetPath = '/dataset/{datasetId}';

  /**
   * Get metadata for a given dataset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataset()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataset$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Dataset>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dataset>;
      })
    );
  }

  /**
   * Get metadata for a given dataset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDataset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataset(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<Dataset> {
    return this.getDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dataset>): Dataset => r.body)
    );
  }

  /** Path part for operation `replaceDataset()` */
  static readonly ReplaceDatasetPath = '/dataset/{datasetId}';

  /**
   * Upload new content for a dataset.
   *
   * Upload a new dataset version. This will replace the content of this dataset, generating a new version.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceDataset$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceDataset$Json$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: {
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Dataset>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ReplaceDatasetPath, 'put');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dataset>;
      })
    );
  }

  /**
   * Upload new content for a dataset.
   *
   * Upload a new dataset version. This will replace the content of this dataset, generating a new version.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceDataset$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceDataset$Json(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: {
}
    },
    context?: HttpContext
  ): Observable<Dataset> {
    return this.replaceDataset$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dataset>): Dataset => r.body)
    );
  }

  /**
   * Upload new content for a dataset.
   *
   * Upload a new dataset version. This will replace the content of this dataset, generating a new version.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: Blob
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Dataset>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ReplaceDatasetPath, 'put');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.body(params.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dataset>;
      })
    );
  }

  /**
   * Upload new content for a dataset.
   *
   * Upload a new dataset version. This will replace the content of this dataset, generating a new version.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: Blob
    },
    context?: HttpContext
  ): Observable<Dataset> {
    return this.replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dataset>): Dataset => r.body)
    );
  }

  /** Path part for operation `deleteDataset()` */
  static readonly DeleteDatasetPath = '/dataset/{datasetId}';

  /**
   * Delete a dataset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDataset()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataset$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteDatasetPath, 'delete');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a dataset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDataset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataset(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateDataset()` */
  static readonly UpdateDatasetPath = '/dataset/{datasetId}';

  /**
   * Update metadata for a given dataset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDataset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataset$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: DatasetData
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Dataset>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.UpdateDatasetPath, 'patch');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dataset>;
      })
    );
  }

  /**
   * Update metadata for a given dataset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDataset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataset(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
      body: DatasetData
    },
    context?: HttpContext
  ): Observable<Dataset> {
    return this.updateDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dataset>): Dataset => r.body)
    );
  }

  /** Path part for operation `getDatasetVersions()` */
  static readonly GetDatasetVersionsPath = '/datasets/{datasetId}/versions';

  /**
   * Get all available versions for a given dataset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasetVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersions$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DatasetVersionList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetVersionsPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatasetVersionList>;
      })
    );
  }

  /**
   * Get all available versions for a given dataset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasetVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersions(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<DatasetVersionList> {
    return this.getDatasetVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<DatasetVersionList>): DatasetVersionList => r.body)
    );
  }

  /** Path part for operation `getDatasetVersion()` */
  static readonly GetDatasetVersionPath = '/datasets/{datasetId}/versions/{datasetVersionId}';

  /**
   * Get a single version for a given dataset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasetVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersion$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DatasetVersion>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetVersionPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.path('datasetVersionId', params.datasetVersionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatasetVersion>;
      })
    );
  }

  /**
   * Get a single version for a given dataset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasetVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersion(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<DatasetVersion> {
    return this.getDatasetVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<DatasetVersion>): DatasetVersion => r.body)
    );
  }

  /** Path part for operation `deleteDatasetVersion()` */
  static readonly DeleteDatasetVersionPath = '/datasets/{datasetId}/versions/{datasetVersionId}';

  /**
   * Delete a dataset version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDatasetVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDatasetVersion$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteDatasetVersionPath, 'delete');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.path('datasetVersionId', params.datasetVersionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a dataset version.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDatasetVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDatasetVersion(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteDatasetVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDatasetVersionStatus()` */
  static readonly GetDatasetVersionStatusPath = '/datasets/{datasetId}/versions/{datasetVersionId}/status';

  /**
   * Get the import status for a dataset version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasetVersionStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionStatus$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DatasetVersionStatus>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetVersionStatusPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.path('datasetVersionId', params.datasetVersionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatasetVersionStatus>;
      })
    );
  }

  /**
   * Get the import status for a dataset version.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasetVersionStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionStatus(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;
    },
    context?: HttpContext
  ): Observable<DatasetVersionStatus> {
    return this.getDatasetVersionStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<DatasetVersionStatus>): DatasetVersionStatus => r.body)
    );
  }

  /** Path part for operation `getDatasetVersionData()` */
  static readonly GetDatasetVersionDataPath = '/datasets/{datasetId}/versions/{datasetVersionId}/data';

  /**
   * Get the content of a dataset version, paged json or Excel export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasetVersionData$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionData$Json$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DatasetVersionContent>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetVersionDataPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.path('datasetVersionId', params.datasetVersionId, {});
      rb.query('offset', params.offset, {});
      rb.query('count', params.count, {});
      rb.query('download', params.download, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatasetVersionContent>;
      })
    );
  }

  /**
   * Get the content of a dataset version, paged json or Excel export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasetVersionData$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionData$Json(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<DatasetVersionContent> {
    return this.getDatasetVersionData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DatasetVersionContent>): DatasetVersionContent => r.body)
    );
  }

  /**
   * Get the content of a dataset version, paged json or Excel export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetDatasetVersionDataPath, 'get');
    if (params) {
      rb.path('datasetId', params.datasetId, {});
      rb.path('datasetVersionId', params.datasetVersionId, {});
      rb.query('offset', params.offset, {});
      rb.query('count', params.count, {});
      rb.query('download', params.download, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get the content of a dataset version, paged json or Excel export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(
    params: {

    /**
     * Dataset ID
     */
      datasetId: DatasetId;

    /**
     * Dataset Version ID
     */
      datasetVersionId: DatasetVersionId;

    /**
     * Row offset, zero-based. If not specified, download will start at the first row. If an offset is provided, a row count is required as well.
     */
      offset?: number;

    /**
     * Row count. Omit to download all rows.
     */
      count?: number;

    /**
     * Download a xlsx. Selection via Accept header is currently not possible.
     */
      download?: boolean;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `putMaterials()` */
  static readonly PutMaterialsPath = '/masterdata/materials';

  /**
   * Upload material master data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMaterials()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMaterials$Response(
    params: {
      body: MaterialList
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.PutMaterialsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload material master data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putMaterials$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMaterials(
    params: {
      body: MaterialList
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putMaterials$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPlanningUnits()` */
  static readonly GetPlanningUnitsPath = '/admin/planningunits';

  /**
   * Get list of planning units which exist in the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanningUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanningUnits$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanningUnitList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPlanningUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanningUnitList>;
      })
    );
  }

  /**
   * Get list of planning units which exist in the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanningUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanningUnits(
    params?: {
    },
    context?: HttpContext
  ): Observable<PlanningUnitList> {
    return this.getPlanningUnits$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanningUnitList>): PlanningUnitList => r.body)
    );
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/me';

  /**
   * Get the currently logged-in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Get the currently logged-in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(
    params?: {
    },
    context?: HttpContext
  ): Observable<User> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `getMeOperations()` */
  static readonly GetMeOperationsPath = '/me/operations';

  /**
   * Get operations allowed for the currently logged-in user, based on the assigned roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeOperations$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OperationList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMeOperationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OperationList>;
      })
    );
  }

  /**
   * Get operations allowed for the currently logged-in user, based on the assigned roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeOperations(
    params?: {
    },
    context?: HttpContext
  ): Observable<OperationList> {
    return this.getMeOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationList>): OperationList => r.body)
    );
  }

  /** Path part for operation `getMePat()` */
  static readonly GetMePatPath = '/me/tokens';

  /**
   * Get active PAT of the currently logged-in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMePat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMePat$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PatList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetMePatPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatList>;
      })
    );
  }

  /**
   * Get active PAT of the currently logged-in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMePat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMePat(
    params?: {
    },
    context?: HttpContext
  ): Observable<PatList> {
    return this.getMePat$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatList>): PatList => r.body)
    );
  }

  /** Path part for operation `createMePat()` */
  static readonly CreateMePatPath = '/me/tokens';

  /**
   * Create a PAT for the currently logged-in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMePat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMePat$Response(
    params: {
      body: PatCreationParameters
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Pat>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.CreateMePatPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pat>;
      })
    );
  }

  /**
   * Create a PAT for the currently logged-in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMePat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMePat(
    params: {
      body: PatCreationParameters
    },
    context?: HttpContext
  ): Observable<Pat> {
    return this.createMePat$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pat>): Pat => r.body)
    );
  }

  /** Path part for operation `deleteMePat()` */
  static readonly DeleteMePatPath = '/me/tokens/{tokenId}';

  /**
   * Delete a PAT for the currently logged-in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMePat()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMePat$Response(
    params: {

    /**
     * Token ID
     */
      tokenId: TokenId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteMePatPath, 'delete');
    if (params) {
      rb.path('tokenId', params.tokenId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a PAT for the currently logged-in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMePat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMePat(
    params: {

    /**
     * Token ID
     */
      tokenId: TokenId;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteMePat$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getBackgroundLog()` */
  static readonly GetBackgroundLogPath = '/admin/background/log';

  /**
   * Get a list of logged events which ran in background.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBackgroundLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundLog$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EventList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetBackgroundLogPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventList>;
      })
    );
  }

  /**
   * Get a list of logged events which ran in background.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBackgroundLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundLog(
    params?: {
    },
    context?: HttpContext
  ): Observable<EventList> {
    return this.getBackgroundLog$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventList>): EventList => r.body)
    );
  }

  /** Path part for operation `getBackgroundStatus()` */
  static readonly GetBackgroundStatusPath = '/admin/background/status';

  /**
   * Get current status of background activities.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBackgroundStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatusList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetBackgroundStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusList>;
      })
    );
  }

  /**
   * Get current status of background activities.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBackgroundStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatusList> {
    return this.getBackgroundStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusList>): StatusList => r.body)
    );
  }

  /** Path part for operation `blockBackgroundProcess()` */
  static readonly BlockBackgroundProcessPath = '/admin/background/status/{eventType}/block';

  /**
   * Block an background process event type from being processed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockBackgroundProcess$Response(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Status>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.BlockBackgroundProcessPath, 'post');
    if (params) {
      rb.path('eventType', params.eventType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Status>;
      })
    );
  }

  /**
   * Block an background process event type from being processed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockBackgroundProcess(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<Status> {
    return this.blockBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `unblockBackgroundProcess()` */
  static readonly UnblockBackgroundProcessPath = '/admin/background/status/{eventType}/unblock';

  /**
   * Unblock a background process event type from being processed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unblockBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockBackgroundProcess$Response(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Status>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.UnblockBackgroundProcessPath, 'post');
    if (params) {
      rb.path('eventType', params.eventType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Status>;
      })
    );
  }

  /**
   * Unblock a background process event type from being processed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unblockBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockBackgroundProcess(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<Status> {
    return this.unblockBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `executeBackgroundProcess()` */
  static readonly ExecuteBackgroundProcessPath = '/admin/background/status/{eventType}/execute';

  /**
   * Explicitly trigger execution of a background process by event type. Also triggers execution if the process is currently blocked, but will not remove the block.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBackgroundProcess$Response(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Status>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ExecuteBackgroundProcessPath, 'post');
    if (params) {
      rb.path('eventType', params.eventType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Status>;
      })
    );
  }

  /**
   * Explicitly trigger execution of a background process by event type. Also triggers execution if the process is currently blocked, but will not remove the block.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBackgroundProcess(
    params: {

    /**
     * Event Type
     */
      eventType: EventType;
    },
    context?: HttpContext
  ): Observable<Status> {
    return this.executeBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `getRoles()` */
  static readonly GetRolesPath = '/admin/roles';

  /**
   * Get list of roles which exist in the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RoleList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleList>;
      })
    );
  }

  /**
   * Get list of roles which exist in the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<RoleList> {
    return this.getRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleList>): RoleList => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/admin/users';

  /**
   * Get list of users available in the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetUsersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserList>;
      })
    );
  }

  /**
   * Get list of users available in the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserList> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserList>): UserList => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/admin/users/{userId}';

  /**
   * Get details of a single user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetUserPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Get details of a single user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(
    params: {

    /**
     * User ID
     */
      userId: UserId;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `getUserPat()` */
  static readonly GetUserPatPath = '/admin/users/{userId}/tokens';

  /**
   * Get active PAT of a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPat$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PatList>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetUserPatPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatList>;
      })
    );
  }

  /**
   * Get active PAT of a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPat(
    params: {

    /**
     * User ID
     */
      userId: UserId;
    },
    context?: HttpContext
  ): Observable<PatList> {
    return this.getUserPat$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatList>): PatList => r.body)
    );
  }

  /** Path part for operation `createUserPat()` */
  static readonly CreateUserPatPath = '/admin/users/{userId}/tokens';

  /**
   * Create a PAT for a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserPat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPat$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      body: PatCreationParameters
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Pat>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.CreateUserPatPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pat>;
      })
    );
  }

  /**
   * Create a PAT for a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserPat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPat(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      body: PatCreationParameters
    },
    context?: HttpContext
  ): Observable<Pat> {
    return this.createUserPat$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pat>): Pat => r.body)
    );
  }

  /** Path part for operation `deleteUserPat()` */
  static readonly DeleteUserPatPath = '/admin/users/{userId}/tokens/{tokenId}';

  /**
   * Delete a PAT for a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserPat()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserPat$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Token ID
     */
      tokenId: TokenId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteUserPatPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('tokenId', params.tokenId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a PAT for a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserPat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserPat(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Token ID
     */
      tokenId: TokenId;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteUserPat$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `assignUserRole()` */
  static readonly AssignUserRolePath = '/admin/users/{userId}/roles/{roleName}';

  /**
   * Assign a role to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserRole$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Role Name
     */
      roleName: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.AssignUserRolePath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleName', params.roleName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Assign a role to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserRole(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Role Name
     */
      roleName: string;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.assignUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `unassignUserRole()` */
  static readonly UnassignUserRolePath = '/admin/users/{userId}/roles/{roleName}';

  /**
   * Unassign a role from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserRole$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Role Name
     */
      roleName: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.UnassignUserRolePath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleName', params.roleName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Unassign a role from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserRole(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Role Name
     */
      roleName: string;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.unassignUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `assignUserDataset()` */
  static readonly AssignUserDatasetPath = '/admin/users/{userId}/datasets/{datasetId}';

  /**
   * Assign a dataset to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignUserDataset()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserDataset$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.AssignUserDatasetPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('datasetId', params.datasetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Assign a dataset to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignUserDataset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserDataset(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.assignUserDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `unassignUserDataset()` */
  static readonly UnassignUserDatasetPath = '/admin/users/{userId}/datasets/{datasetId}';

  /**
   * Unassign a dataset from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignUserDataset()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserDataset$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.UnassignUserDatasetPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('datasetId', params.datasetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Unassign a dataset from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignUserDataset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserDataset(
    params: {

    /**
     * User ID
     */
      userId: UserId;

    /**
     * Dataset ID
     */
      datasetId: DatasetId;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.unassignUserDataset$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `assignUserPlanningUnit()` */
  static readonly AssignUserPlanningUnitPath = '/admin/users/{userId}/planningunits/{planningUnit}';

  /**
   * Assign a planning unit to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignUserPlanningUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserPlanningUnit$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      planningUnit: PlanningUnit;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.AssignUserPlanningUnitPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('planningUnit', params.planningUnit, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Assign a planning unit to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignUserPlanningUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserPlanningUnit(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      planningUnit: PlanningUnit;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.assignUserPlanningUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `unassignUserPlanningUnit()` */
  static readonly UnassignUserPlanningUnitPath = '/admin/users/{userId}/planningunits/{planningUnit}';

  /**
   * Unassign a planning unit from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignUserPlanningUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserPlanningUnit$Response(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      planningUnit: PlanningUnit;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.UnassignUserPlanningUnitPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('planningUnit', params.planningUnit, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Unassign a planning unit from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignUserPlanningUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserPlanningUnit(
    params: {

    /**
     * User ID
     */
      userId: UserId;
      planningUnit: PlanningUnit;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.unassignUserPlanningUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

}

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DatasetId } from "../api/models";

export interface DatasetChangedMessage {
	datasetId: DatasetId;
}

@Injectable()
export class UpdateMessageService {
	private subject = new Subject<DatasetChangedMessage>();

	sendFileChangedMessage(datasetId: DatasetId) {
		this.subject.next({ datasetId: datasetId });
	}

	getMessage(): Observable<DatasetChangedMessage> {
		return this.subject.asObservable();
	}
}

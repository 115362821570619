<div class="title">
	<h1>Background Status</h1>
</div>

<mat-card role="region">
	<mat-card-content>
		<table mat-table [dataSource]="dataSourceStatus" aria-label="Background Status List">
			<ng-container matColumnDef="eventType">
				<th mat-header-cell *matHeaderCellDef>Event Type</th>
				<td mat-cell *matCellDef="let element">{{element.eventType}}</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let element">
					{{element.statusMessage}}
					<span *ngIf="element.blocked">
						Blocked by {{element.blockedBy}} at
						<time timeago [date]="element.blockedAt" [live]="true" [dateTime]="element.blockedAt"></time>
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="progress">
				<th mat-header-cell *matHeaderCellDef>Progress</th>
				<td mat-cell *matCellDef="let element">
					<mat-progress-bar [value]="element.progress * 100"></mat-progress-bar>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef>Actions</th>
				<td mat-cell *matCellDef="let element">
					<div class="action-list">
						<button mat-raised-button *ngIf="!element.isInvididualStatus && element.blocked"
							(click)="unblockBackgroundProcess(element.eventType)" title="Allow automatic execution of this process">Unblock</button>
						<button mat-raised-button *ngIf="!element.isInvididualStatus && !element.blocked"
							(click)="blockBackgroundProcess(element.eventType)" title="Prevent automatic execution of this process">Block</button>
						<button mat-raised-button *ngIf="!element.isInvididualStatus"
							(click)="triggerBackgroundProcess(element.eventType)" title="Explicitly execute this process now, even if it is blocked">Trigger Now</button>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumnsStatus"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsStatus"></tr>
		</table>
	</mat-card-content>
</mat-card>

<div class="title">
	<h1>Event Log</h1>
</div>

<mat-card role="region">
	<mat-card-content>
		<table mat-table [dataSource]="dataSourceEvents" aria-label="Background Event Log">
			<ng-container matColumnDef="eventType">
				<th mat-header-cell *matHeaderCellDef>Event Type</th>
				<td mat-cell *matCellDef="let element">{{element.eventType}}</td>
			</ng-container>

			<ng-container matColumnDef="startedAt">
				<th mat-header-cell *matHeaderCellDef>Started At</th>
				<td mat-cell *matCellDef="let element">
					<time timeago [date]="element.startedAt" [live]="true" [dateTime]="element.startedAt"></time>
				</td>
			</ng-container>

			<ng-container matColumnDef="completedAt">
				<th mat-header-cell *matHeaderCellDef>Completed At</th>
				<td mat-cell *matCellDef="let element">
					<time timeago [date]="element.completedAt" [live]="true" [dateTime]="element.completedAt"
						*ngIf="element.completedAt"></time>
					<span *ngIf="!element.completedAt">Not Completed</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="message">
				<th mat-header-cell *matHeaderCellDef>Message</th>
				<td mat-cell *matCellDef="let element">{{element.statusMessage}}</td>
			</ng-container>

			<ng-container matColumnDef="success">
				<th mat-header-cell *matHeaderCellDef>Success</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon *ngIf="element.success" aria-label="Success">check</mat-icon>
					<mat-icon *ngIf="!element.success" aria-label="Failure">bolt</mat-icon>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumnsEvents"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsEvents"></tr>
		</table>
	</mat-card-content>
</mat-card>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../api/services';
import { UpdateMessageService } from '../services/update-message.service';

@Component({
  selector: 'pdx-create-dataset-dialog',
  templateUrl: './create-dataset-dialog.component.html',
  styleUrls: ['./create-dataset-dialog.component.css']
})
export class CreateDatasetDialogComponent {
  datasetName: string = "";

  constructor(public dialogRef: MatDialogRef<CreateDatasetDialogComponent>, private updateMessage: UpdateMessageService, private apiClient: ApiService) { }

  onClickCancel() {
    this.dialogRef.close();
  }

  onClickCreate() {
    if (this.datasetName) {
      this.apiClient.createDataset({ datasetName: this.datasetName })
        .subscribe(response => {
          this.updateMessage.sendFileChangedMessage(response.id);
          this.dialogRef.close();
        });
    }
  }
}

import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RowMessage } from '../api/models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'pdx-row-messages-dialog',
  templateUrl: './row-messages-dialog.component.html',
  styleUrls: ['./row-messages-dialog.component.css']
})
export class RowMessagesDialogComponent implements AfterViewInit {
  displayedColumns: string[] = ['row', 'message'];
  dataSource = new MatTableDataSource<RowMessage>();

  constructor(public dialogRef: MatDialogRef<RowMessagesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: RowMessage[]) { }

  ngAfterViewInit(): void {
    this.dataSource.data = this.data;
  }

  onClickClose() {
    this.dialogRef.close();
  }
}

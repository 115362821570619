import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Dataset, DatasetId, DatasetVersionId, DatasetVersionState, DatasetVersionStatus, OperationList, RowMessage, View, ViewId } from '../api/models';
import { ApiService } from '../api/services';
import { CreateDatasetDialogComponent } from '../create-dataset-dialog/create-dataset-dialog.component';
import { Operations } from '../operations';
import { RowMessagesDialogComponent } from '../row-messages-dialog/row-messages-dialog.component';
import { UpdateMessageService } from '../services/update-message.service';

export interface ListRow {
  dataset: Dataset,
  progress: DatasetVersionStatus | null,
};

interface IdError {
  int: number;
  message: string;
}

@Component({
  selector: 'pdx-dataset-view',
  templateUrl: './dataset-view.component.html',
  styleUrls: ['./dataset-view.component.css']
})
export class DatasetViewComponent implements OnInit {
  stopProgressRefresh$: Subject<any> = new Subject();
  dataSets: ListRow[] = [];
  views: View[] = [];
  me: OperationList = { operations: [] };

  Operations = Operations;

  datasetVersionStateError = DatasetVersionState.Error;
  datasetVersionStateExtracted = DatasetVersionState.Extracted;
  datasetVersionStatePendingExtract = DatasetVersionState.PendingExtract;

  downloadingDatasets: number[] = [];
  messagesForDatasetDownloads: IdError[] = [];

  downloadingViews: number[] = [];
  messagesForViewDownloads: IdError[] = [];

  constructor(private apiClient: ApiService, private updateMessage: UpdateMessageService, private dialog: MatDialog) { }

  ngOnInit() {
    this.refresh();

    this.updateMessage.getMessage().subscribe(() => this.refresh());
  }

  hasOperation(operation: string): boolean {
    return this.me.operations.includes(operation);
  }
  showDetails(messages: RowMessage[]) {
    this.dialog.open(RowMessagesDialogComponent, { data: messages });
  }

  createDatasetClicked() {
    this.dialog.open(CreateDatasetDialogComponent);
  }

  clearDataset(datasetId: DatasetId) {
    this.apiClient.replaceDataset$Json({ datasetId: datasetId, body: {} })
      .subscribe(
        {
          next: (value) => {
            for (const row of this.dataSets) {
              if (row.dataset.id == datasetId) {
                row.dataset = value
                break;
              }
            }
          },
          error: (err) => {
            for (const row of this.dataSets) {
              if (row.dataset.id == datasetId) {
                row.progress = {
                  id: 0,
                  progress: 1,
                  versionState: this.datasetVersionStateError,
                  datasetId: datasetId,
                  statusMessage: err,
                }
                break;
              }
            }
          }
        }

      )
  }

  isDownloadingDataset(datasetId: DatasetId): boolean {
    return this.downloadingDatasets.findIndex(g => g == datasetId) >= 0;
  }

  downloadDataset(datasetId: DatasetId, datasetVersionId: DatasetVersionId, name: string) {
    this.downloadingDatasets.push(datasetVersionId);
    var idx = this.downloadingDatasets.findIndex(f => f == datasetId);
    if (idx >= 0) {
      this.downloadingDatasets.splice(idx, 1);
    }

    this.apiClient.getDatasetVersionData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(
      {
        datasetId: datasetId,
        datasetVersionId: datasetVersionId,
        count: undefined,
        offset: undefined,
        download: true,
      }
    ).subscribe(
      {
        next: (value: Blob) => {
          const a = document.createElement("a");
          a.download = name + ".xlsx";
          a.href = window.URL.createObjectURL(value);
          a.click();

          var idx = this.downloadingDatasets.findIndex(f => f == datasetId);
          if (idx >= 0) {
            this.downloadingDatasets.splice(idx, 1);
          }
        },
        error: (error: any) => {
          var idx = this.downloadingDatasets.findIndex(f => f == datasetId);
          if (idx >= 0) {
            this.downloadingDatasets.splice(idx, 1);
          }

          if (error instanceof HttpErrorResponse) {
            this.messagesForDatasetDownloads.push({ int: datasetId, message: error.message });
          } else {
            this.messagesForDatasetDownloads.push({ int: datasetId, message: "Unknown Error" });
          }

        },
      }
    );
  }

  isDownloadingView(viewId: ViewId): boolean {
    return this.downloadingViews.findIndex(g => g == viewId) >= 0;
  }

  downloadView(viewId: ViewId, name: string) {
    this.downloadingViews.push(viewId);
    var idx = this.downloadingViews.findIndex(f => f == viewId);
    if (idx >= 0) {
      this.downloadingViews.splice(idx, 1);
    }

    this.apiClient.getViewData$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(
      {
        viewId: viewId,
        count: undefined,
        offset: undefined,
        download: true,
      }
    ).subscribe(
      {
        next: (value: Blob) => {
          const a = document.createElement("a");
          a.download = name + ".xlsx";
          a.href = window.URL.createObjectURL(value);
          a.click();

          var idx = this.downloadingViews.findIndex(f => f == viewId);
          if (idx >= 0) {
            this.downloadingViews.splice(idx, 1);
          }
        },
        error: (error: any) => {
          var idx = this.downloadingViews.findIndex(f => f == viewId);
          if (idx >= 0) {
            this.downloadingViews.splice(idx, 1);
          }

          if (error instanceof HttpErrorResponse) {
            this.messagesForViewDownloads.push({ int: viewId, message: error.message });
          } else {
            this.messagesForViewDownloads.push({ int: viewId, message: "Unknown Error" });
          }

        },
      }
    );
  }


  refresh() {

    this.apiClient.getMeOperations().subscribe(me => {
      this.me = me;
    });

    this.apiClient.getViews().subscribe(response => {
      this.views = response.views.sort((a, b) => a.name.localeCompare(b.name));
    })

    this.apiClient.getDatasets().subscribe(response => {
      this.dataSets = response.datasets.map<ListRow>(
        dataset => {
          return { dataset: dataset, progress: null };
        }
      ).sort((a, b) => a.dataset.name.localeCompare(b.dataset.name));

      var needRefresh: DatasetVersionId[] = [];

      for (const row of this.dataSets) {
        // If we are pending extract, refresh status periodically.
        // If we have an error, refresh status once, to display details on refresh.
        if (row.dataset.latestVersion?.versionState == DatasetVersionState.PendingExtract ||
          row.dataset.latestVersion?.versionState == DatasetVersionState.Error) {
          needRefresh.push(row.dataset.latestVersion.id)
          break;
        }
      }

      if (needRefresh.length > 0) {
        timer(300, 300)
          .pipe(takeUntil(this.stopProgressRefresh$))
          .subscribe(() => {
            var anyRefreshLeft: boolean = false;
            for (const row of this.dataSets) {
              // If we are pending extract, refresh status periodically.
              // If we have an error, refresh status once, to display details on refresh.
              if (row.dataset.latestVersion?.versionState == DatasetVersionState.PendingExtract ||
                (row.dataset.latestVersion?.versionState == DatasetVersionState.Error && row.progress == null)
              ) {
                anyRefreshLeft = true;
                var update$ = this.apiClient.getDatasetVersionStatus({ datasetId: row.dataset.id, datasetVersionId: row.dataset.latestVersion.id });
                update$.subscribe(status => {
                  if (row.dataset.latestVersion) {
                    row.dataset.latestVersion.versionState = status.versionState;
                    row.dataset.latestVersion.rowCount = status.rowCount;
                    row.progress = status;
                    row.progress.progress *= 100;
                  }
                });
              }
            }
            if (!anyRefreshLeft) {
              // Stop refreshing
              this.stopProgressRefresh$.next({});
            }
          });
      }
    });
  }


}

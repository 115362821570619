import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Dataset, DatasetId, DatasetList, PlanningUnit, PlanningUnitList, Role, RoleList, User, UserId, UserList } from '../api/models';
import { ApiService } from '../api/services';

@Component({
  selector: 'pdx-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, AfterViewInit {
  userList: UserList = { users: [] };
  roleList: RoleList = { roles: [] };
  datasetList: DatasetList = { datasets: [] };
  planningUnitList: PlanningUnitList = { planningUnits: [] };
  displayedColumnsUsers: string[] = ['id', 'aadId', 'roles', 'datasets', 'planningUnits'];
  dataSourceUsers = new MatTableDataSource<User>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiClient: ApiService, private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit() {
    this.refresh();
  }

  ngAfterViewInit() {
    this.dataSourceUsers.paginator = this.paginator;
    this.dataSourceUsers.sort = this.sort;
  }

  refresh() {
    this.apiClient.getUsers().subscribe(userList => {
      userList.users.sort((a, b) => a.aadId.localeCompare(b.aadId));
      this.userList = userList;
      this.dataSourceUsers.data = this.userList.users;
    });
    this.apiClient.getRoles().subscribe(roleList => {
      roleList.roles.sort((a, b) => a.name.localeCompare(b.name));
      this.roleList = roleList;
    });
    this.apiClient.getDatasets().subscribe(datasetList => {
      datasetList.datasets.sort((a, b) => a.name.localeCompare(b.name));
      this.datasetList = datasetList;
    });
    this.apiClient.getPlanningUnits().subscribe(planningUnitList => {
      planningUnitList.planningUnits.sort((a, b) => a.localeCompare(b));
      this.planningUnitList = planningUnitList;
    });
  }

  unassignRole(userId: UserId, roleName: string) {
    this.apiClient.unassignUserRole({ userId: userId, roleName: roleName.trim() }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  assignRole(userId: UserId, roleName: string) {
    this.apiClient.assignUserRole({ userId: userId, roleName: roleName.trim() }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  unassignDataset(userId: UserId, datasetId: DatasetId) {
    this.apiClient.unassignUserDataset({ userId: userId, datasetId: datasetId }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  assignDataset(userId: UserId, datasetId: DatasetId) {
    this.apiClient.assignUserDataset({ userId: userId, datasetId: datasetId }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  unassignPlanningUnit(userId: UserId, planningUnit: PlanningUnit) {
    this.apiClient.unassignUserPlanningUnit({ userId: userId, planningUnit: planningUnit }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  assignPlanningUnit(userId: UserId, planningUnit: PlanningUnit) {
    this.apiClient.assignUserPlanningUnit({ userId: userId, planningUnit: planningUnit }).subscribe(newUser => {
      var index = this.userList.users.findIndex(user => user.id == newUser.id)
      if (index < 0) {
        this.userList.users.push(newUser);
      } else {
        this.userList.users[index] = newUser;
      }
      this.dataSourceUsers.data = this.userList.users;
    })
  }

  getDataset(datasetID: DatasetId): Dataset | undefined {
    return this.datasetList.datasets.find(s => s.id == datasetID);
  }

  isRoleAssignable(roleName: string): boolean {
    var role = this.roleList.roles.find(role => role.name == roleName);
    if (role == null) {
      return false;
    }
    return role.assignable;
  }

  getAssignableRoles(): Role[] {
    return this.roleList.roles.filter(r => r.assignable);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}

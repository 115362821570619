export const environment = {
  production: false,
  enableMsal: true,
  msalConfig: {
    auth: {
      clientId: '4244a658-7adf-4151-8752-a9aba28f068d',
      authority: 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55'
    }
  },
  profileApiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  backendApiConfig: {
    uri: '/api/v1'
  },
};

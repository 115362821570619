import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import { Event, EventList, EventType, Status, StatusList } from '../api/models';
import { ApiService } from '../api/services';

@Component({
  selector: 'app-background-status',
  templateUrl: './background-status.component.html',
  styleUrls: ['./background-status.component.css']
})
export class BackgroundStatusComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumnsEvents: string[] = ['eventType', 'startedAt', 'completedAt', 'message', 'success'];
  displayedColumnsStatus: string[] = ['eventType', 'status', 'progress', 'actions'];

  eventList: EventList = { events: [] };
  statusList: StatusList = { status: [] };

  dataSourceEvents = new MatTableDataSource<Event>();
  dataSourceStatus = new MatTableDataSource<Status>();

  stopProgressRefresh: boolean = false;

  constructor(private apiClient: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  ngAfterViewInit(): void {
    this.startRefreshInterval();
  }

  startRefreshInterval(): void {
    setTimeout(async () => {
      await this.refresh();
      if (!this.stopProgressRefresh) {
        this.startRefreshInterval();
      }
    }, 2000);
  }

  ngOnDestroy(): void {
    this.stopProgressRefresh = true;
  }

  unblockBackgroundProcess(eventType: EventType): void {
    this.apiClient.unblockBackgroundProcess({ eventType: eventType })
      .subscribe((updatedStatus) => {
        var idx = this.dataSourceStatus.data.findIndex((value) => value.eventType == eventType);
        if (idx < 0) {
          this.dataSourceStatus.data.push(updatedStatus);
        } else {
          this.dataSourceStatus.data[idx] = updatedStatus;
        }
        this.dataSourceStatus.data = this.dataSourceStatus.data
      });
  }

  blockBackgroundProcess(eventType: EventType): void {
    this.apiClient.blockBackgroundProcess({ eventType: eventType })
      .subscribe((updatedStatus) => {
        var idx = this.dataSourceStatus.data.findIndex((value) => value.eventType == eventType);
        if (idx < 0) {
          this.dataSourceStatus.data.push(updatedStatus);
        } else {
          this.dataSourceStatus.data[idx] = updatedStatus;
        }
        this.dataSourceStatus.data = this.dataSourceStatus.data
      });
  }

  triggerBackgroundProcess(eventType: EventType): void {
    this.apiClient.executeBackgroundProcess({ eventType: eventType })
      .subscribe((updatedStatus) => {
        var idx = this.dataSourceStatus.data.findIndex((value) => value.eventType == eventType);
        if (idx < 0) {
          this.dataSourceStatus.data.push(updatedStatus);
        } else {
          this.dataSourceStatus.data[idx] = updatedStatus;
        }
        this.dataSourceStatus.data = this.dataSourceStatus.data
      });
  }

  async refresh(): Promise<void> {
    const getStatus$ = this.apiClient.getBackgroundStatus();
    const getEvents$ = this.apiClient.getBackgroundLog();

    let results = await Promise.all([firstValueFrom(getStatus$), firstValueFrom(getEvents$)]);

    if (!this.arraysEqual(this.dataSourceStatus.data, results[0].status)) {
      this.dataSourceStatus.data = results[0].status || [];
    }
    if (!this.arraysEqual(this.dataSourceEvents.data, results[1].events)) {
      this.dataSourceEvents.data = results[1].events.sort((e1, e2) => e2.startedAt.localeCompare(e1.startedAt)) || [];
    }
  }

  /** Compare two arrays of equally typed objects for differences.
   * This is used to prevent UI flicker if data has not changed,
   * while still allowing quick refresh rates at the cost of some extra background processing.
   */
  arraysEqual<T extends object>(a: T[], b: T[]): boolean {
    if (a === b) {
      return true;
    }
    if (a === null || b === null) {
      return false;
    }
    if (a.length != b.length) {
      return false;
    }
    for (let i = 0, len = a.length; i < len; i++) {
      if (!this.deepEquals(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }

  deepEquals<T extends object>(obj1: T, obj2: T) {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null ||
      typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys = Object.keys(obj1);

    for (const key of keys) {
      const val1 = (obj1 as any)[key];
      const val2 = (obj2 as any)[key];
      // Note: If we have nested objects, we need to use deepEquals here as well (not implemented here)
      if (val1 !== val2) {
        return false;
      }
    }
    return true;
  }
}

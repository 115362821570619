import { Injectable } from '@angular/core';
import { AccountInfo } from "@azure/msal-common";
import { environment } from 'src/environments/environment';
import { User } from '../api/models';
import { ApiService } from '../api/services';

export type ProfileInfo = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  /**
   * Whether a local login session is found.
   * Used to display appropriate controls, not used for actual security (That is done in the backend).
   */
  isAuthenticated: boolean = false;
  activeAccount: AccountInfo | null = null;
  profileInfo: ProfileInfo | null = null;
  backendVersion: string | null = null;
  userInfo: User | null = null;

  constructor(
    private apiClient: ApiService,
  ) { }

  setActiveAccount(activeAccount: AccountInfo | null) {
    this.activeAccount = activeAccount;
    this.isAuthenticated = activeAccount != null;

    if (this.isAuthenticated) {
      if (environment.enableMsal) {
        // this.http.get(environment.profileApiConfig.uri)
        //   .subscribe(profile => {
        //     this.profileInfo = profile;
        //   });
        this.profileInfo = {
          givenName: "", // Test (not available from active account)
          surname: "", // User (not available from active account)
          id: activeAccount?.username, // UsernamE@BASFAD.BASF.NET
          userPrincipalName: activeAccount?.name, // Test User
        };
        this.backendVersion = "dev";
      } else {
        this.profileInfo = {
          givenName: "Test",
          surname: "User",
          id: "localuser@test.basfad.basf.net",
          userPrincipalName: "Test User",
        };
      }
      this.apiClient.getMe().subscribe({
        next: (user) => {
          this.userInfo = user;
        },
        error: (err) => {
          // TODO: Store error to be displayed somewhere?
          console.error("error loading user info:", err)
          this.userInfo = null;
        }
      })
      this.apiClient.getVersion().subscribe({
        next: (version) => {
          this.backendVersion = version.backendVersion;
        },
        error: (err) => {
          console.error("error loading backend version:", err)
          this.backendVersion = "ERR";
        },
      })
    } else {
      this.profileInfo = null;
      this.userInfo = null;
    }
  }
}

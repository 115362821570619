import { Component, Input, OnInit } from '@angular/core';

import { DatasetId } from '../api/models';
import { FileUploadService } from '../services/file-upload.service';

@Component({
    selector: 'pdx-file-upload-button',
    templateUrl: 'file-upload-button.component.html',
    styleUrls: ["file-upload-button.component.css"]
})

export class FileUploadButtonComponent implements OnInit {
    @Input() datasetId: DatasetId | null = null;

    constructor(private fileUploadService: FileUploadService) { }

    ngOnInit() { }

    async onFileSelected(event: Event) {
        if (!this.datasetId) {
            return;
        }

        const input = event.target as HTMLInputElement;
        const file: File = input!.files![0];

        this.fileUploadService.uploadFile(file, this.datasetId);
    }
}

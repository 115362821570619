<div class="title">
	<h1>Dataset Manager</h1>
	<button mat-raised-button (click)="refresh()"><mat-icon>refresh</mat-icon>Refresh</button>
	<button mat-raised-button (click)="createDatasetClicked()" *ngIf="hasOperation(Operations.CreateDataset)"><mat-icon>add</mat-icon>Create Dataset</button>
</div>

<h2 *ngIf="views && views.length > 0">Views</h2>

<div class="tileList" *ngIf="views && views.length > 0">
	<mat-card *ngFor="let row of views">
		<mat-card-header>
			<mat-card-title-group>
				<mat-card-title><b>{{row.name}}</b></mat-card-title>
				<mat-card-subtitle>{{row.contentType}}</mat-card-subtitle>
			</mat-card-title-group>
		</mat-card-header>
		<mat-card-content>
			<span>Read-only view to download</span>
		</mat-card-content>
		<mat-card-actions>
			<button *ngIf="hasOperation(Operations.GetViewData)" mat-icon-button
				aria-label="Download View" title="Download View"
				(click)="downloadView(row.id, row.name)"><mat-icon>download</mat-icon></button>
		</mat-card-actions>
	</mat-card>
</div>

<h2>Data Sets</h2>

<div class="tileList">
	<mat-card *ngFor="let row of dataSets">
		<mat-card-header>
			<mat-card-title-group>
				<mat-card-title><b>{{row.dataset.name}}</b></mat-card-title>
				<mat-card-subtitle>{{row.dataset.contentType}}</mat-card-subtitle>
			</mat-card-title-group>
		</mat-card-header>
		<mat-card-content>
			<span *ngIf="row.dataset.latestVersion">
				<span *ngIf="row.dataset.latestVersion.versionState == datasetVersionStatePendingExtract">
					<div><mat-icon>access_time</mat-icon> Dataset is pending extraction</div>
					<!-- TODO: Show previous version that would be considered for merge and upload -->
				</span>
				<span *ngIf="row.dataset.latestVersion.versionState == datasetVersionStateExtracted">
					<div>Currently contains {{row.dataset.latestVersion.rowCount}} rows.</div>
				</span>
				<span *ngIf="row.dataset.latestVersion.versionState == datasetVersionStateError" class="error">
					<div><mat-icon>error</mat-icon>Error during extraction.</div>
					<!-- TODO: Show previous version that would be considered for merge and upload -->
				</span>
				<div><time timeago [date]="row.dataset.latestVersion.createdAt" [live]="true"
						[dateTime]="row.dataset.latestVersion.createdAt"></time> by
					{{row.dataset.latestVersion.createdBy}}</div>
				<div></div>
				<div *ngIf="row.progress">
					<span *ngIf="row.progress.versionState == datasetVersionStateError" class="error">
						<mat-icon>error</mat-icon>{{row.progress.statusMessage}}
					</span>
					<span *ngIf="row.progress.versionState != datasetVersionStateError">
						<mat-progress-bar [value]="row.progress.progress * 100"></mat-progress-bar>
						{{row.progress.statusMessage}}
					</span>
				</div>
			</span>
			<div *ngIf="!row.dataset.latestVersion">
				Dataset is empty.
			</div>
		</mat-card-content>
		<mat-card-actions>
			<div *ngIf="row.progress && row.progress.rowMessages && row.progress.rowMessages.length > 0">
				<button mat-button (click)="showDetails(row.progress.rowMessages)">
					<mat-icon>table_view</mat-icon> Details
				</button>
			</div>
			<button *ngIf="hasOperation(Operations.GetDatasetVersionData) && row.dataset.latestVersion" mat-icon-button
				aria-label="Download Dataset" title="Download Dataset"
				(click)="downloadDataset(row.dataset.id, row.dataset.latestVersion!.id, row.dataset.name)"><mat-icon>download</mat-icon></button>
			<button *ngIf="hasOperation(Operations.ReplaceDataset)" mat-icon-button color="warn"
				aria-label="Erase content of data set" title="Erase content"
				(click)="clearDataset(row.dataset.id)"><mat-icon>playlist_remove</mat-icon></button>
			<pdx-file-upload-button *ngIf="hasOperation(Operations.ReplaceDataset)"
				[datasetId]="row.dataset.id"></pdx-file-upload-button>
		</mat-card-actions>
	</mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import { FileUpload, FileUploadService } from '../services/file-upload.service';

interface FileUploadRow {
  upload: FileUpload;
  upload$: Observable<FileUpload>;
}

@Component({
  selector: 'pdx-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.css']
})
export class FileUploadListComponent implements OnInit {
  constructor(private fileUploadService: FileUploadService) { }

  files: FileUploadRow[] = [];
  fileExcel = faFileExcel;
  sumUploading = 0;
  sumCompleted = 0;
  sumFailed = 0;

  insertTestFiles() {
    this.files.push({
      upload: {
        file: new File([], "dummy"),
        fileName: "Dummy.xlsx",
        completed: false,
        progress: 40,
        error: null,
      },
      upload$: new Observable<FileUpload>(),
    });
    this.files.push({
      upload: {
        file: new File([], "dummy"),
        fileName: "Dummy.xlsx",
        completed: true,
        progress: 100,
        error: null,
      },
      upload$: new Observable<FileUpload>(),
    });
    this.files.push({
      upload: {
        file: new File([], "dummy"),
        fileName: "Dummy.xlsx",
        completed: true,
        progress: 40,
        error: "Upload Cancelled",
      },
      upload$: new Observable<FileUpload>(),
    });
  }

  updateCounts() {
    var uploading = 0;
    var completed = 0;
    var failed = 0;
    for (const row of this.files) {
      if (row.upload.completed) {
        if (row.upload.error) {
          failed += 1;
        } else {
          completed += 1;
        }
      } else {
        uploading += 1;
      }
    }
    this.sumUploading = uploading;
    this.sumCompleted = completed;
    this.sumFailed = failed;
  }

  ngOnInit() {
    // For testing styling and rendering, uncomment this to get permanently visible examples
    // this.insertTestFiles();
    this.updateCounts();

    this.fileUploadService.afterUploadStarted().subscribe(event => {
      // TODO: Get initial upload from this event
      var row: FileUploadRow = {
        upload: event.upload,
        upload$: event.upload$,
      }
      row.upload$.subscribe(upload => {
        row.upload = upload;
        this.updateCounts();
      });

      this.files.push(row);
      this.updateCounts();
    });
  }


}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { Dataset, DatasetId } from '../api/models';
import { ApiService } from "../api/services";
import { UpdateMessageService } from "./update-message.service";

export interface FileUpload {
	/**
	 * Local file, with original name
	 */
	file: File;
	/**
	 * File name used during upload (can be different from local file name)
	 */
	fileName: string;
	progress: number;
	completed: boolean;

	/**
	 * If an error occurred, or the upload was cancelled, this will be set. Otherwise null.
	 */
	error: string | null;
}

export interface FileUploadStartedEvent {
	upload: FileUpload;
	upload$: Observable<FileUpload>;
}

@Injectable()
export class FileUploadService {
	private subjectUploadStarted = new Subject<FileUploadStartedEvent>();

	constructor(private apiClient: ApiService, private updateMessage: UpdateMessageService, private dialog: MatDialog) { }

	afterUploadStarted(): Observable<FileUploadStartedEvent> {
		return this.subjectUploadStarted.asObservable();
	}

	uploadFile(file: File, datasetId: DatasetId): Observable<FileUpload> {
		const fileName = file.name;

		var fileUpload: FileUpload = {
			file: file,
			fileName: fileName,
			progress: 0,
			completed: false,
			error: null,
		};

		const subjectUploadProgress = new Subject<FileUpload>();
		this.subjectUploadStarted.next({ upload: fileUpload, upload$: subjectUploadProgress });
		subjectUploadProgress.next(fileUpload);

		// const existingFileList$ = this.apiClient.getFiles();
		// existingFileList$.subscribe(async existingFileList => {
		// 	var conflictingFile: ApiFile | undefined = existingFileList.files.find(existingFile => existingFile.name == fileName)

		var upload$: Observable<Dataset>;

		// if (conflictingFile) {
		// 	const dialogRef = this.dialog.open(FileReplaceDialogComponent, {
		// 		data: { existingFile: conflictingFile, replacementFile: file },
		// 	});

		// 	const choice = await firstValueFrom(dialogRef.afterClosed());
		// 	if (choice == FileReplaceChoice.Replace) {
		// 		upload$ = this.apiClient.replaceFile({ fileId: conflictingFile.id, body: file })
		// 	} else if (choice == FileReplaceChoice.Rename) {
		// 		const adjustedFileName = Date.now() + file.name;
		// 		fileUpload.fileName = adjustedFileName
		// 		// TODO: Rename serverside by incrementing? Appending date+increment? (respecting file extension)
		// 		upload$ = this.apiClient.createFile({ fileName: adjustedFileName, body: file });
		// 	} else { // Cancel or undefined
		// 		fileUpload.error = "Upload Cancelled";
		// 		fileUpload.completed = true;
		// 		subjectUploadProgress.next(fileUpload);
		// 		return;
		// 	}
		// }
		upload$ = this.apiClient.replaceDataset$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet({ datasetId: datasetId, body: file });

		// TODO: The generated client does not support enabling upload progress.
		// See https://blog.angular-university.io/angular-file-upload/
		// TODO: Find alternative which supports progress reporting
		upload$.subscribe(
			{
				next: (response) => {
					fileUpload.progress = 100;
					fileUpload.completed = true;
					subjectUploadProgress.next(fileUpload);

					this.updateMessage.sendFileChangedMessage(response.id);
				},
				error: (err) => {
					fileUpload.progress = 100;
					fileUpload.completed = true;
					fileUpload.error = err;
					subjectUploadProgress.next(fileUpload);
				}
			});

		return subjectUploadProgress.asObservable();
	}
}
